<template>
  <div class="tiku_indeterminate contentComBox">
    <div class="questionType">{{ setTitle(curData) }}</div>
    <div>
      <div class="questionTitle update_font_size">
<!--        <span>{{dataIndex+1}}、</span>-->
        <div class="inderQuestionDetail">   
          <span>{{data.questionIndex}}、</span>
          <span v-html="data.subjectTitle"></span>
        </div>
        
        <img v-if="data.subjectTitlePic" :src="data.subjectTitlePic" class="subjectTitlePic">
      </div>
    </div>
    <div class="tiku_indeterminate_tabsBox">
      <div class="tiku_indeterminate_tabsBox_header">
        <div class="tiku_indeterminate_tabsBox_header_item">
          <span v-for="(item,index) in data.subjectList" :key="index" :class="smallQuestionIndex == index ? 'checked' :''" @click="tabSmallQuestion(item,index)">第{{index+1}}小题</span>
        </div>
      </div>
    </div>
    <div class="shortTitle">
      {{curData.subjectTitle}}
    </div>
    
   
    <!--答案解析-->
    <div class="tiku_indeterminate_radioAnalysis_nalysisWarp">
      <div  class="tiku_indeterminate_radioAnalysis_header">
       
        <div class="tiku_indeterminate_radioAnalysis_header_anwserAna" style="margin-left:20px">
          <div class="tiku_indeterminate_radioAnalysis_header_anwserAna_title">您的作答：</div>
          <div class="tiku_indeterminate_radioAnalysis_header_anwserAna_text"  style="padding:0px">
            <p class="jiexiBox" v-html="answerInfo.doneAnswer"></p>
          </div>
        </div>
       
        <div class="tiku_indeterminate_radioAnalysis_header_anwserAna" style="margin-left:20px">
          <div class="tiku_indeterminate_radioAnalysis_header_anwserAna_title">答题解析：</div>
          <div class="tiku_indeterminate_radioAnalysis_header_anwserAna_text" style="padding:0px">
            <p class="jiexiBox" v-html="answerInfo.analysisText"></p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {subject_analysis} from "@/api/exam";

import VueUeditorWrap from 'vue-ueditor-wrap';
import { Loading } from "element-ui";
export default {
  props: ["data", "dataIndex"],
  components: {VueUeditorWrap},
  data() {
    return {
      answerInfo:{},
      checkList: [],
      smallQuestionIndex: 0,
      testTextarea: '', // 简答题的textarea
      myConfig: {
        // 如果需要上传功能,找后端小伙伴要服务器接口地址
        serverUrl: '',
        // 你的UEditor资源存放的路径,相对于打包后的index.html
        UEDITOR_HOME_URL: '/UEditor/',
        // 编辑器不自动被内容撑高
        autoHeightEnabled: false,
        // 初始容器高度
        initialFrameHeight: 300,
        // 初始容器宽度
        initialFrameWidth: '100%',
        // 关闭自动保存
        enableAutoSave: false,
        // 字数
        wordCount: false,
        // 元素路径
        elementPathEnabled: false,
        // 图片拉伸
        imageScaleEnabled: false,
        // 右键菜单
        // enableContextMenu: false,
        // 自定义工具栏，需要额外选项可以参考ueditor.config.js
        toolbars: [
          [
            'bold', 'italic', 'underline', '|', 'fontsize', 'fontfamily', '|', 'kityformula',
            'justifyleft', 'justifycenter', 'justifyright', 'justifyjustify', '|',
          ]
        ],
        contextMenu: [
          {
            label: '',
            cmdName: 'selectall',
          },
          {
            label: '',
            cmdName: 'cleardoc',
            exec: function () {
              if (confirm('确定清空当前文档么？')) {
                this.execCommand("cleardoc");
              }
            }
          }
        ]
      },
      anaList: {},
      curData: {}
    };
  },
  name: "shortAnswer",
  methods: {
    initAnalysis() {
           
           subject_analysis({ subjectId: this.analysObj.subjectId }).then((res) => {
               this.analyNewObj = res.data.data;
           });
       },
    changeAnswer(index){
      this.setSubmitAnswer();
      this.smallQuestionIndex = index;
      this.curData = this.data.subjectList[index];
      this.testTextarea = this.curData.doneAnswer;
    },
    setSubmitAnswer(){
      let subjectId = this.data.subjectList[this.smallQuestionIndex].subjectId;
      let obj = {
        subjectId: subjectId,
        answerId: '',
        dataIndex: this.dataIndex,
        type: "shortAnswer",
        doneAnswer:this.testTextarea
      };
      this.$emit("answerRadio", obj);

    },
    getAnswer(){
      this.setSubmitAnswer();
      return this.smallQuestionIndex;
    },
    setAnswer(index){
      this.smallQuestionIndex = index;
      this.curData = this.data.subjectList[index];
      this.testTextarea = this.curData.doneAnswer;
    },
    setMyAnwser(list) {
      if (list.answerList && list.answerList.length > 0) {
        let val = list.answerList.filter((item) => {
          return list.doneAnswerIds === item.answerId;
        });
        if (val.length == 0) {
          return "/";
        } else {
          return val[0].indexes;
        }
      }
    },
    setRightAnwser(list) {
      console.log(list);
      if (list.answerList && list.answerList.length > 0) {
        let val = [];
        list.answerList.map((item) => {
          if(item.isRight === 1) {
            val.push(item.indexes)
          }
        });
        return val.join(',');
      }
    },
    tabSmallQuestion(val,index) {
      let subjectId = this.data.subjectList.length?this.data.subjectList[this.smallQuestionIndex].subjectId:this.data.subjectId;
      let obj = {
        subjectId: subjectId,
        answerId: val,
        dataIndex: this.dataIndex,
        type: "shortAnswer",
        doneAnswer:this.testTextarea
      };
     
      this.smallQuestionIndex = index;
      
      this.$emit("answerRadio", obj);
      this.$emit("changePaper", this.dataIndex,index);
      this.curData = this.data.subjectList[index];
      this.testTextarea = this.curData.doneAnswer;
      
    },
    changeQuestion(type) {
      console.log(this.smallQuestionIndex);
      if(type === 'prev'&&this.smallQuestionIndex===0) {

      }else if(type === 'next'&&this.smallQuestionIndex===this.data.subjectList.length-1) {

      }else {
        let index = type === 'prev'?this.smallQuestionIndex-1:this.smallQuestionIndex+1;
        this.tabSmallQuestion(index);
      }
    },
    
    setTitle(item){
      switch(item.classify){
        case "1":
          return "单项选择题";
          break;
        case "2":
          return "多项选择题";
          break;
        case "3":
          return "判断题";
          break;
        case "4":
          return "不定项选择题";
          break;
        case "5":
          return "计算分析题";
          break;
        case "6":
          return "综合题";
          break;
        case "7":
          return "简答题";
          break;
        case "8":
          return "计算回答题";
          break;
        case "9":
          return "案例分析题";
          break;
        case "10":
          return "计算题";
          break;
        case "11":
          return "综合分析题";
          break;

      }

    },
  },
  created() {
    this.curData = this.data.subjectList[0];
    this.testTextarea = this.curData.doneAnswer;
    // this.smallQuestionIndex = this.curData.subjectId;
  },
  mounted() {
    window.addEventListener("setItem", option => {

        if (option.key === "isAnalysInfo") {
            this.answerInfo = JSON.parse(option.newValue);
        }
    });
    // this.$nextTick(()=>{
    //   this.curData = this.data.subjectList[0];
    //   this.testTextarea = this.curData.doneAnswer;
    // })
    
  },
};
</script>
<style lang="less">
.jiexiBox{
  p{
    margin: 0px;
    padding:0px;
  }
}
.contentComBox{
  .shortTitle{
    margin-top:26px;
    margin-left: 30px;
    font-size: 18px;
    font-family: SourceHanSansCN, SourceHanSansCN;
    font-weight: 500;
    color: #28313D;
  }
  .inderQuestionDetail{
    margin-top:22px;
    width: calc(100% - 50px);
    margin-left: 20px;
    font-size: 16px;
    font-family: SourceHanSansCN, SourceHanSansCN;
    //font-weight: 500;
    color: #28313D;
  }
  .questionType{
    box-sizing: border-box;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin-left: 30px;
    margin-top: 20px;
    height: 20px;
    font-size: 14px;
    font-family: SourceHanSansCN, SourceHanSansCN;
    font-weight: 500;
    color: #28313D;
  }
  .questionTitle{
    margin-left: 10px;
    font-size: 14px;
    font-family: SourceHanSansCN, SourceHanSansCN;
    font-weight: 500;
    color: #28313D;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin-bottom: 20px;
    text-align: left;
    display: flex;
    align-items: flex-start;
  }
}
</style>