<template>
  <div class="tiku_indeterminate contentComBox">
    <div class="questionType">{{ setTitle(curData) }}</div>
    <div>
      <div class="questionTitle update_font_size">
<!--        <span>{{dataIndex+1}}、</span>-->
        <div class="inderQuestionDetail">
          <span>{{data.questionIndex}}、</span>
          <span v-html="data.subjectTitle"></span>
        </div>
        
        <img v-if="data.subjectTitlePic" :src="data.subjectTitlePic" class="subjectTitlePic">
      </div>
    </div>
    <div class="tiku_indeterminate_tabsBox">
      <div class="tiku_indeterminate_tabsBox_header">
        <div class="tiku_indeterminate_tabsBox_header_item">
          <span v-for="(item,index) in data.subjectList" :key="index" :class="smallQuestionIndex === index?'checked':''" @click="tabSmallQuestion(item,index)">第{{index+1}}小题</span>
        </div>
      </div>
    </div>
    <div class="tiku_indeterminate_question_text" style="margin-left:30px;">
      {{curData.subjectTitle}}
      <img v-if="curData.subjectTitlePic" :src="curData.subjectTitlePic" class="subjectTitlePic">
    </div>
    <div class="examText_main_right_checkBox_question_option">
      <div class="examText_main_right_checkBox_question_item" style="padding-left:36px;">
        <child v-if="curData" :childData="curData" />
      </div>
    </div>
    <analysisCom v-if="curData" :rowObj="curData"/>

  </div>
</template>

<script>
import {subject_analysis} from "@/api/exam";
import { Loading } from "element-ui";
import child from "./child";
import analysisCom from "./analysisCom";
export default {
  props: ["data", "dataIndex"],
  components: {child,analysisCom},
  data() {
    return {
      isShowJX: false,
      smallQuestionIndex: 0,
     
      curData: {},
    };
  },
  name: "indeterminate",
  methods: {
    changeAnswer(index){
      this.smallQuestionIndex = index;
      this.isShowJX = false;
      this.curData = this.data.subjectList[index];
     
    },
    getAnswer(){
 
      return this.smallQuestionIndex;
    },
    setAnswer(index){
      this.smallQuestionIndex = index;
      this.isShowJX = false;
      this.curData = this.data.subjectList[index];
     
    },
    startAnaly(){
      this.initAnalysis();
    },
    setMyAnwser(list) {
      if (list.answerList && list.answerList.length > 0) {
        let val = list.answerList.filter((item) => {
          return list.doneAnswerIds === item.answerId;
        });
        if (val.length == 0) {
          return "/";
        } else {
          return val[0].indexes;
        }
      }
    },
    setRightAnwser(list) {
      if (list.answerList && list.answerList.length > 0) {
        let val = [];
        list.answerList.map((item) => {
          if(item.isRight === 1) {
            val.push(item.indexes)
          }
        });
        return val.join(',');
      }
    },
    tabSmallQuestion(val,index) {
      // this.smallQuestionIndex = val.subjectId;
      this.smallQuestionIndex = index;
      this.isShowJX = false;
      this.curData = this.data.subjectList[index];
      
      let subjectId = this.data.subjectList.length?this.data.subjectList[this.smallQuestionIndex].subjectId:this.data.subjectId;
      let obj = {
        subjectId: subjectId,
        dataIndex: this.dataIndex,
        type: "indeter",
      };
      this.$emit("answerRadio", obj);
      this.$emit("changePaper", this.dataIndex,index);
    },
    save_subject_Tab(val) {
      let subjectId = this.data.subjectList.length?this.data.subjectList[this.smallQuestionIndex].subjectId:this.data.subjectId;
      let obj = {
        subjectId: subjectId,
        answerId: val,
        dataIndex: this.dataIndex,
        type: "indeter",
      };
      this.$emit("answerRadio", obj);
    },
    changeQuestion(type) {
      if(type === 'prev'&&this.smallQuestionIndex===0) {

      }else if(type === 'next'&&this.smallQuestionIndex===this.data.subjectList.length-1) {

      }else {
        let index = type === 'prev'?this.smallQuestionIndex-1:this.smallQuestionIndex+1;
        this.tabSmallQuestion(index);
      }
    },
    
    setTitle(item){
      switch(item.classify){
        case "1":
          return "单项选择题";
          break;
        case "2":
          return "多项选择题";
          break;
        case "3":
          return "判断题";
          break;
        case "4":
          return "不定项选择题";
          break;
        case "5":
          return "计算分析题";
          break;
        case "6":
          return "综合题";
          break;
        case "7":
          return "简答题";
          break;
        case "8":
          return "计算回答题";
          break;
        case "9":
          return "案例分析题";
          break;
        case "10":
          return "计算题";
          break;
        case "11":
          return "综合分析题";
          break;

      }

    },
  },
  created() {

  },
  mounted() {
    this.$nextTick(()=>{
      this.curData = this.data.subjectList[0];
    })
    
  },
};
</script>
<style lang="less">
.jiexiBox{
  p{
    margin: 0px;
    padding:0px;
  }
}
.contentComBox{
  width: calc(100% - 35px);
  .inderQuestionDetail{
    margin-top:22px;
    width: calc(100% - 50px);
    margin-left: 20px;
    font-size: 16px;
    font-family: SourceHanSansCN, SourceHanSansCN;
    //font-weight: 500;
    color: #28313D;
  }
  .questionType{
    box-sizing: border-box;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin-left: 30px;
    margin-top: 20px;
    height: 20px;
    font-size: 14px;
    font-family: SourceHanSansCN, SourceHanSansCN;
    font-weight: 500;
    color: #28313D;
  }
  .questionTitle{
    margin-left: 10px;
    font-size: 14px;
    font-family: SourceHanSansCN, SourceHanSansCN;
    font-weight: 500;
    color: #28313D;
    width: calc(100% - 20px);
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin-bottom: 20px;
    text-align: left;
    display: flex;
    align-items: flex-start;
  }
}
</style>