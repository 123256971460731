<template>
  <div class="tiku_radio  contentComBox">
    <div class="questionType" v-if="data.classify == '1'">单项选择题</div>
    <div class="questionType" v-if="data.classify == '3'">判断题</div>
    <div class="tiku_radio_question_boxNew">
      <div class="questionTitle">
        {{data.questionIndex}}、{{ data.subjectTitle }}
      </div>
      <div class="tiku_radio_question_radio_box">
        <child :childData="data" />
       
      </div>
    </div>
    <analysisCom :rowObj="data"/>
  </div>
</template>

<script>
import { subject_analysis } from "@/api/exam";
import { Loading } from "element-ui";
import child from "./child";
import analysisCom from "./analysisCom";
export default {
  props: ["data", "dataIndex"],
  components: {child,analysisCom},
  data() {
    return {
      radio: "",
      
      anaList: {},
    };
  },
  name: "radioCom",
  
  
  methods: {
    getAnswer(){
      // this.save_subject_Tab(this.checkList);
      return 'radio';
    },
    setMyAnwser(list) {
      if (list.answerList && list.answerList.length > 0) {
        let val = list.answerList.filter((item) => {
          return list.doneAnswerIds === item.answerId;
        });
        if (val.length == 0) {
          return "/";
        } else {
          return val[0].indexes;
        }
      }
    },
    setRightAnwser(list) {
      if (list.answerList && list.answerList.length > 0) {
        let val = list.answerList.filter((item) => {
          return item.isRight == "1";
        });
        return val[0].indexes;
      }
    },
    radioChange(val) {
      let obj = {
        subjectId: this.data.subjectId,
        answerId: val,
        dataIndex: this.dataIndex,
        type: "radio",
      };
      this.$emit("answerRadio", obj);
    },
    // initAnalysis() {
    //   this.loading = Loading.service({
    //     text: "解析中...",
    //     background: "rgba(0, 0, 0, 0.1)",
    //   });
    //   subject_analysis({ subjectId: this.data.subjectId }).then((res) => {
    //     this.isShowJX = this.$store.getters["study/getQuestionSwipeIndex"].isShow;
    //     this.anaList = res.data.data;
    //     this.loading.close();
    //   });
    // },
  },
  created() {},
  mounted() {},
};
</script>
<style lang="less">
.jiexiBox{
  p{
    margin: 0px;
    padding:0px;
  }
}
.contentComBox{
  .questionType{
    box-sizing: border-box;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin-left: 30px;
    margin-top: 20px;
    height: 20px;
    font-size: 14px;
    font-family: SourceHanSansCN, SourceHanSansCN;
    font-weight: 500;
    color: #28313D;
  }
  .questionTitle{
    margin-left: 10px;
    font-size: 14px;
    font-family: SourceHanSansCN, SourceHanSansCN;
    font-weight: 500;
    color: #28313D;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin-bottom: 20px;
    text-align: left;
    display: flex;
    align-items: flex-start;
  }
}
</style>