<template>
    <div class="tiku_ChildBox">
        <div v-for="(item, index) in listData.answerList" v-if="listData" :key="index" :style="index != 0 ? 'margin-top:15px' :''" class="rows">
            <div :class="setItemStyle(item)">{{ item.indexes }}</div>
            <div class="right">{{item.answerName}}</div>
        </div>
    </div>
  </template>
  
  <script>

  export default {
    props: ["childData"],
    components: {},
    data() {
      return {
        listData:[]
      };
    },
    name: "radioCom",
    
    watch: {
      childData: {
        handler(val) {
          console.log("===",val);
          this.listData = val;
        },
        deep: true
      }
    },
    methods: {
      setItemStyle(item){
        if(item){
          if(item.isRight == '1'){
              return "isRight left"
          }else if(item.isRight == '2'){

            return "isError left"

          }else{
            if(this.listData.doneAnswerIds){
              let vals = this.listData.doneAnswerIds.split(",");
              let questionsPics = vals.filter((v) => {
                return v == item.answerId;
              });
              if(questionsPics.length > 0){
                return "isError left"
              }else{
                return "isWei left"
              }

            }else{
              return "isWei left"
            }
            
          }
        }
          
      }
      
    },
    created() {},
    mounted() {
        this.listData = this.childData;
    },
  };
  </script>
  <style lang="less">
.tiku_ChildBox{
    width: 100%;
    height: 100%;
    
    .rows{
        display: flex;
        flex-direction: row;
        margin-left: 10px;
        
        .left{
            width: 18px;
            height: 18px;
            
            font-size: 12px;
            font-family: SourceHanSansCN, SourceHanSansCN;
            font-weight: 400;
            color: #FFFFFF;
            border-radius: 50%;
            text-align: center;
            line-height: 18px;
            
        }
        .right{
            font-size: 14px;
            font-family: SourceHanSansCN, SourceHanSansCN;
            font-weight: 400;
            color: #28313D;
            margin-left: 10px;

        }
        .isRight{
                background: #48BA83;
            }
            .isError{
                background: #FF7975;
            }
            .isWei{
                background: #F3F5F6;
                border: 1px solid #979CA4;
                font-size: 12px;
                font-family: SourceHanSansCN, SourceHanSansCN;
                font-weight: 400;
                color: #7A818B;
            }
    }
}
  </style>