var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"questionArea"},[_c('div',{staticClass:"questionArea_top"},[_c('el-carousel',{ref:"carouselREf",attrs:{"autoplay":false,"initial-index":_vm.initIndex,"loop":false,"arrow":"never","interval":300000000000000000000,"indicator-position":"none"},on:{"change":_vm.cardChange}},_vm._l((_vm.AnswerList),function(item,index){return _c('el-carousel-item',{key:index},[(item.classify == '1' || item.classify == '3')?_c('radioCom',{ref:_vm.setItemRef,refInFor:true,attrs:{"dataIndex":index,"data":item},on:{"answerRadio":_vm.answerRadio}}):_vm._e(),(item.classify == '2')?_c('checkboxCom',{ref:_vm.setItemRef,refInFor:true,attrs:{"dataIndex":index,"data":item},on:{"answerRadio":_vm.answerRadio}}):_vm._e(),(
            item.classify == '4' ||
            item.classify == '11'
          )?_c('indeterminate',{ref:_vm.setItemRef,refInFor:true,attrs:{"ref":"changeAnswer","dataIndex":index,"data":item},on:{"answerRadio":_vm.answerRadio,"changePaper":_vm.changePaper}}):_vm._e(),(
            item.classify == '5' ||
            item.classify == '6' ||
            item.classify == '7' ||
            item.classify == '8' ||
            item.classify == '9'
          )?_c('shortAnswer',{ref:_vm.setItemRef,refInFor:true,attrs:{"ref":"changeAnswer","dataIndex":index,"data":item},on:{"answerRadio":_vm.answerRadio,"changePaper":_vm.changePaper}}):_vm._e(),(item.classify == '10')?_c('radioGroup',{ref:_vm.setItemRef,refInFor:true,attrs:{"ref":"changeAnswer","dataIndex":index,"data":item},on:{"answerRadio":_vm.answerRadio,"changePaper":_vm.changePaper}}):_vm._e()],1)}),1)],1),_c('div',{staticClass:"questionArea_bottomN"},[_c('div',{staticClass:"questionArea_bottomN_operate_btnNew"},[_c('el-button',{staticStyle:{"margin-right":"70px"},on:{"click":function($event){return _vm.preQuestion()}}},[_vm._v("上一题")]),_c('el-button',{attrs:{"type":"primary"},on:{"click":function($event){return _vm.nextQuestion()}}},[_vm._v("下一题")])],1)])])
}
var staticRenderFns = []

export { render, staticRenderFns }