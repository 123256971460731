<template>
  <div class="tiku_radio contentComBox">
    <div class="questionType">多项选择题</div>
    <div class="tiku_radio_question_boxNew">
      <div class="questionTitle">
        {{ data.questionIndex }}、{{ data.subjectTitle }}
<!--        {{ dataIndex + 1 }}、{{ data.subjectTitle }}-->
      </div>
      <div class="tiku_radio_question_radio_box">
        <child :childData="data" />
      </div>
    </div>
    <analysisCom :rowObj="data"/>
  </div>
</template>

<script>
import { subject_analysis } from "@/api/exam";
import { Loading } from "element-ui";
import child from "./child";
import analysisCom from "./analysisCom";
export default {
  props: ["data", "dataIndex"],
  components: {child,analysisCom},
  data() {
    return {
      checkList: [],
      anaList: {},
    };
  },
  name: "checkboxCom",
  

  methods: {
    getAnswer(){
      // this.save_subject_Tab(this.checkList);
      return 'radio';
    },
    radioChange(val) {
      let obj = {
        subjectId: this.data.subjectId,
        answerId: val,
        dataIndex: this.dataIndex,
        type: "checkbox",
      };
      this.$emit("answerRadio", obj);
    },
    setMyAnwser(list) {
      if (list.answerList && list.answerList.length > 0) {
        if (
          list.doneAnswerIds != null &&
          list.doneAnswerIds.indexOf(",") != -1
        ) {
          let items = list.doneAnswerIds.split(",");
          let val = [];
          items.map((res) => {
            list.answerList.map((item) => {
              if (res === item.answerId) {
                val.push(item);
              }
            });
          });
          if (val.length == 0) {
            return "/";
          } else {
            let str = "";
            val.map((item) => {
              str += item.indexes;
            });
            return str;
          }
        } else {
          let val = list.answerList.filter((item) => {
            return list.doneAnswerIds === item.answerId;
          });
          if (val.length == 0) {
            return "/";
          } else {
            return val[0].indexes;
          }
        }
      }
    },
    setRightAnwser(list) {
      if (list.answerList && list.answerList.length > 0) {
        let val = list.answerList.filter((item) => {
          return item.isRight == "1";
        });
        let str = "";
        val.map((item) => {
          str += item.indexes;
        });
        return str;
      }
    },
    initAnalysis() {
      this.loading = Loading.service({
        text: "解析中...",
        background: "rgba(0, 0, 0, 0.1)",
      });
      subject_analysis({ subjectId: this.data.subjectId }).then((res) => {
        
        this.anaList = res.data.data;
        this.loading.close();
      });
    },
  },
  created() {},
  mounted() {
    this.checkList = this.data.doneAnswerIds?this.data.doneAnswerIds.split(','):[]
  },
};
</script>
<style lang="less">
.jiexiBox{
  p{
    margin: 0px;
    padding:0px;
  }
}
.contentComBox{
  .questionType{
    box-sizing: border-box;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin-left: 30px;
    margin-top: 20px;
    height: 20px;
    font-size: 14px;
    font-family: SourceHanSansCN, SourceHanSansCN;
    font-weight: 500;
    color: #28313D;
  }
  .questionTitle{
    margin-left: 10px;
    font-size:14px;
    font-family: SourceHanSansCN, SourceHanSansCN;
    font-weight: 500;
    color: #28313D;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin-bottom: 20px;
    text-align: left;
    display: flex;
    align-items: flex-start;
  }
}
</style>