<template>
  <div class="analysisCom">
    <div style="width:100%;height:15px;"></div>
    <div class="topBox">
      <img class="leftIcon" src="@/assets/tiku/answerIcon.png" />
      <div class="row1">
        <span class="span1">答题情况:</span>
        <span class="span2">{{isRightText}}</span>
        <img class="icon" v-if="isRightText == '正确'" src="../../../../assets/tiku/rightIcon.png" />
        <img class="icon" v-if="isRightText == '错误'" src="../../../../assets/tiku/answerError.png" />
        <img class="icon" v-if="isRightText == '未作答'" src="../../../../assets/tiku/answerWei.png" />
      </div>
      <div class="row2">
        <span class="span1">您的答案:</span>
        <span style="margin-left:12px;color: #48BA83;font-size: 14px;">{{ setMyAnwser(analysObj) }}</span>
        <span style="margin-left:48px" class="span1">参考答案:</span>
        <span
          style="margin-left:12px;color: #0092FF;font-size: 14px;"
        >{{ setRightAnwser(analysObj) }}</span>
        <span style="margin-left:48px" class="span1">全站正确率:</span>
        <span
          style="margin-left:12px;color: #0092FF;font-size: 14px;"
        >{{questionObj.totalAccuracy}}</span>
      </div>
      <div class="row3">
        <span class>本题总答题:{{questionObj.countNumber}}次</span>
        <span style="margin-left:18px;">
          答对:
          <span style="color: #48BA83;">{{questionObj.rightNumber}}</span>次
        </span>
        <span style="margin-left:18px;">
          答错:
          <span style="color:#FF7975;">{{questionObj.errorNumber}}</span>次
        </span>

        <span style="margin-left:18px;">正确率:</span>
        <span style="color: #0092FF;">{{questionObj.accuracy}}</span>
      </div>
    </div>
    <div class="anaylsText">
      <div class="titleText">答案解析:</div>
      <div class="detail" v-html="analyNewObj.analysisText"></div>
    </div>
  </div>
</template>
  
  <script>
import { subject_analysis,questionStatistics } from "@/api/exam";
export default {
  props: ["rowObj"],
  components: {},
  data() {
    return {
      analysObj: {},
      isRightText: "",
      analyNewObj: {
        totalAccuracy: "",
        countNumber: "",
        rightNumber: "",
        errorNumber: "",
        accuracy: "",
        analysisText: ""
      },
      questionObj:{
        totalAccuracy: "",
        countNumber: "",
        rightNumber: "",
        errorNumber: "",
        accuracy: "",
      }
    };
  },
  name: "analysisCom",

  watch: {
    rowObj: {
      handler(val) {
        this.analysObj = val;
        this.getIsRight(val);
        this.initAnalysis();
      },
      deep: true
    }
    
  },
  methods: {
    initAnalysis() {
      subject_analysis({ subjectId: this.analysObj.subjectId }).then(res => {
        this.analyNewObj = res.data.data;
      });
      
    },
    getIsRight(list) {
      if (list.isRight == "1") {
        this.isRightText = "正确";
      } else if (list.isRight == "2") {
        this.isRightText = "错误";
      } else {
        this.isRightText = "未作答";
      }
    },
    setMyAnwser(list) {
      if (list.answerList && list.answerList.length > 0) {
        if (
          list.doneAnswerIds != null &&
          list.doneAnswerIds.indexOf(",") != -1
        ) {
          let items = list.doneAnswerIds.split(",");
          let val = [];
          items.map(res => {
            list.answerList.map(item => {
              if (res === item.answerId) {
                val.push(item);
              }
            });
          });
          if (val.length == 0) {
            return "未作答";
          } else {
            let str = "";
            val.map(item => {
              str += item.indexes;
            });
            return str;
          }
        } else {
          let val = list.answerList.filter(item => {
            return list.doneAnswerIds === item.answerId;
          });
          if (val.length == 0) {
            return "未作答";
          } else {
            return val[0].indexes;
          }
        }
      }
    },
    setRightAnwser(list) {
      if (list.answerList && list.answerList.length > 0) {
        let val = list.answerList.filter(item => {
          return item.isRight == "1";
        });

        if (val) {
          let str = new Array();
          val.map((item, index) => {
            str.push(item.indexes);
          });
          return str.join(",");
        }
      }
    }
  },
  created() {},
  mounted() {
    this.analysObj = this.rowObj;
    // this.initAnalysis();
    this.getIsRight(this.analysObj);
    window.addEventListener("setItem", option => {
      if (option.key === "isAnalysInfo") {
        this.analyNewObj = JSON.parse(option.newValue);
        
      }
    });
    window.addEventListener("setItem", option => {
      if (option.key === "isAnalysNewInfo") {
        this.questionObj = JSON.parse(option.newValue);
        
      }
    });
  }
};
</script>
  <style lang="less">
.analysisCom {
  width: 100%;
  height: 100%;
  .topBox {
    margin: auto;
    width: 790px;
    height: 152px;
    background: linear-gradient(360deg, #f7fafd 0%, #f0f7ff 100%);
    border-radius: 12px;
    border: 1px solid #ffffff;
    position: relative;
    .leftIcon {
      position: absolute;
      top: 27px;
      right: 30px;
      width: 150px;
      height: 97px;
    }
    .row1 {
      margin-left: 22px;
      margin-top: 24px;
      display: flex;
      flex-direction: row;
      align-items: center;
      .span1 {
        font-size: 15px;
        font-family: SourceHanSansCN, SourceHanSansCN;
        font-weight: 700;
        color: #4c5158;
      }
      .span2 {
        margin-left: 14px;
        font-size: 15px;
        font-family: SourceHanSansCN, SourceHanSansCN;
        font-weight: 500;
        color: #4c5158;
      }
      .icon {
        margin-left: 4px;
        width: 18px;
        height: 18px;
      }
    }
    .row2 {
      margin-left: 22px;
      margin-top: 16px;
      display: flex;
      flex-direction: row;
      align-items: center;
      .span1 {
        font-size: 14px;
        font-family: SourceHanSansCN, SourceHanSansCN;
        font-weight: 700;
        color: #4c5158;
      }
    }
    .row3 {
      margin-left: 22px;
      margin-top: 16px;
      display: flex;
      flex-direction: row;
      font-size: 14px;
    }
  }
  .anaylsText {
    margin-top: 22px;
    margin-left: 45px;
    width: 790px;
    margin-bottom: 30px;
    text-align: left;
    .titleText {
      font-size: 14px;
      font-family: SourceHanSansCN, SourceHanSansCN;
      font-weight: 700;
      color: #28313d;
    }
    .detail {
      font-size: 14px;
      font-family: SourceHanSansCN, SourceHanSansCN;
      font-weight: 400;
      color: #4c5158;
    }
  }
}
</style>