<template>
  <div class="answerCard">
    <div class="warp">
      <div class="cardTitle">答题卡</div>
      <div class="cardTop">
        <div class="childBox">
          <div class="card_Box_question_top colorRight"></div>
          <div class="card_text">正确</div>
        </div>
        <div class="childBox">
          <div class="card_Box_question_top colorError" style="margin-left:10px;"></div>
          <div class="card_text">错误</div>
        </div>
        <div class="childBox">
          <div class="card_Box_question_top colorWei" style="margin-left:10px;"></div>
          <div class="card_text">未答</div>
        </div>
        <div class="childBox">
          <div class="card_Box_question_top colorWei" style="margin-left:10px;">
            <img src="@/assets/tiku/sign.png" />
          </div>
          <div class="card_text">标记</div>
        </div>
      </div>
      <template v-for="item in list">
        <span class="title">{{ item.name }}</span>
        <div class="content_new">
          <template v-for="(val,index) in item.children">
            <div :class="setCardBoxStyle(val)" @click="jump(val)" v-if="!val.subjectList.length">
              {{ (index + 1) }}
              <span v-if="val.isSign == '1'"></span>
            </div>
            <template v-else v-for="(val1,index1) in val.subjectList">
              <div :class="setCardBoxStyle(val1)" @click="jump(val,val1,index1)">
                {{ (index + 1) +'.'+ (index1 + 1) }}
                <span v-if="val.isSign == '1'"></span>
              </div>
            </template>
          </template>
        </div>
      </template>
    </div>
    <div class="bottomAnswerBox">
      <div class="jisuanBox">
        <img src="@/assets/tiku/jisuan.png" />
        <el-popover
          placement="top"
          width="300"
          trigger="hover"
          popper-class="calculator"
          v-model="visible"
        >
          <div id="box">
            <table>
              <tr>
                <td>
                  <input type="button" value="del" @click="del()" />
                </td>
                <td>
                  <input type="button" value="C" @click="clean()" />
                </td>
                <td colspan="2">
                  <input type="text" style="width: 100px" value v-model="rel" />
                </td>
              </tr>
              <tr>
                <td>
                  <input type="button" value="7" @click="add('7')" />
                </td>
                <td>
                  <input type="button" value="8" @click="add('8')" />
                </td>
                <td>
                  <input type="button" value="9" @click="add('9')" />
                </td>
                <td>
                  <input type="button" value="/" @click="add('/')" />
                </td>
              </tr>

              <tr>
                <td>
                  <input type="button" value="4" @click="add('4')" />
                </td>
                <td>
                  <input type="button" value="5" @click="add('5')" />
                </td>
                <td>
                  <input type="button" value="6" @click="add('6')" />
                </td>
                <td>
                  <input type="button" value="*" @click="add('*')" />
                </td>
              </tr>

              <tr>
                <td>
                  <input type="button" value="1" @click="add('1')" />
                </td>
                <td>
                  <input type="button" value="2" @click="add('2')" />
                </td>
                <td>
                  <input type="button" value="3" @click="add('3')" />
                </td>
                <td>
                  <input type="button" value="-" @click="add('-')" />
                </td>
              </tr>

              <tr>
                <td>
                  <input type="button" value="0" @click="add('0')" />
                </td>
                <td>
                  <input type="button" value="." @click="add('.')" />
                </td>
                <td>
                  <input type="button" value="+" @click="add('+')" />
                </td>
                <td>
                  <input type="button" value="=" v-on:click="result()" />
                </td>
              </tr>
            </table>
          </div>

          <span slot="reference" @click="visible = !visible">
            <span style="cursor:pointer;color:#5F7CFD;">计算器</span>
          </span>
        </el-popover>
      </div>
      <div class="btnBox">
        <div @click="goToReport">查看成绩</div>
        <div style="margin-top:17px;" @click="goToTiku">返回题库</div>
      </div>
    </div>
  </div>
</template>

<script>
import Vue from 'vue'
import {
  subject_list,
  subjectPaperList,
  subject_analysis,
  questionStatistics
} from "@/api/exam";


export default {
  name: "answerCard",
  components: {},
  props: ["AnswerList"],
  watch: {
    AnswerList: {
      handler(val) {
        this.getData(val);
      },
      deep: true
    }
  },
  data() {
    return {
      rel: "",
      visible: false,
      list: [],
      typeList: [
        { type: "1", name: "单选题" },
        { type: "2", name: "多项选择题" },
        { type: "3", name: "判断题" },
        { type: "4", name: "不定项选择题" },
        { type: "5", name: "计算分析题" },
        { type: "6", name: "综合题" },
        { type: "7", name: "简答题" },
        { type: "8", name: "计算回答题" },
        { type: "9", name: "案例分析题" },
        { type: "10", name: "计算题" },
        { type: "11", name: "综合分析题" }
      ],
      completeNumber: 0,
      questionData: {},
      firstId:"",
      secondId:"",
    };
  },
  mounted() {
    // setTimeout(()=>{
    //   this.getData(this.AnswerList);
    // },1500)
    var testReportInfo = localStorage.getItem("testReport");
    
    if (testReportInfo) {
      testReportInfo = JSON.parse(testReportInfo)
      this.firstId = testReportInfo.firstId
      this.secondId = testReportInfo.secondId
    }
  },
  methods: {
    initAnalysis(subjectId) {
      subject_analysis({ subjectId: subjectId }).then(res => {
        Vue.prototype.$resetSetItem('isAnalysInfo',JSON.stringify(res.data.data));
      });
      questionStatistics({ subjectId: subjectId }).then((res)=>{
        Vue.prototype.$resetSetItem('isAnalysNewInfo',JSON.stringify(res.data.data));
      })
    },
    goToReport() {
      this.$router.go(-1);
    },
    goToTiku() {
      this.$router.push({
        path: "/examcenter",
        query:{
          firstId:this.firstId,
          secondId:this.secondId
        }
      });
    },
    setCardBoxStyle(item) {
      if (item.isRight == "1") {
        return "card_Box_question colorRight";
      } else if (item.isRight == "2") {
        return "card_Box_question colorError";
      } else {
        return "card_Box_question colorWei"; //rgb(151, 156, 164)
      }
    },
    getData(data) {
      if (data.length > 0) {
        this.list = [];
        // this.completeNumber = data.finallyAnswerId||data.paperSubjectList[0].subjectId;
        this.completeNumber = data[0].subjectId;
        this.initAnalysis(data[0].subjectId);
        this.questionData = data;
        this.typeList.map(v => {
          if (data) {
            let paper = data.filter(val => val.classify === v.type);
            if (paper.length) {
              this.list.push({ name: v.name, children: paper });
            }
          }
        });
      }
    },
    jump(data, subjectList, index1) {
      this.completeNumber = data.subjectId;
      this.AnswerList.finallyAnswerId = data.subjectId;
      let index = 0;
      this.questionData.map((v, i) => {
        if (v.subjectId === data.subjectId) {
          index = i;
        }
      });
      if (subjectList) {
        this.completeNumber = subjectList.subjectId;
        this.AnswerList.finallyAnswerId = subjectList.subjectId;
        this.initAnalysis(subjectList.subjectId);
        this.$emit("jumpTo", index, subjectList.subjectId, index1);
      } else {
        this.completeNumber = data.subjectId;
        this.initAnalysis(data.subjectId);
        this.$emit("jumpTo", index);
      }
    },
    jumpTo(data, two) {
      this.questionData.map((v, i) => {
        if (i === data) {
          if (v.subjectList.length) {
            this.AnswerList.finallyAnswerId = v.subjectList[two || 0].subjectId;
            this.completeNumber = v.subjectList[two || 0].subjectId;
          } else {
            this.AnswerList.finallyAnswerId = v.subjectId;
            this.completeNumber = v.subjectId;
          }
        }
      });
    },
    goQuestion(data) {
      this.completeNumber = data.subjectId;
    },
    // 计算器
    add(index) {
      //这里就是按键绑定的方法，把获取到的值拼接到rel字符串上
      this.rel += index;
    },
    result() {
      this.rel = eval(this.rel); //这里是用eval方法进行一个计算
      this.rel = String(this.rel); //这里的目的是为了显示在显示栏的数字还是字符串，只有字符串才能进行退格，归零的操作
    },
    del() {
      //这个就是退格的操作，用字符串的substring方法截取，每一次截取都是从第0个开始，到长度的前一个，就相当于退格了。
      this.rel = this.rel.substring(0, this.rel.length - 1);
    },
    clean() {
      //这里就是归零的方法，通过给结果赋一个空字符串的方法来实现归零的操作，当然也可以使用删除的方法，例如unshift方法或者pop方法，直接赋值为空比较简便。
      this.rel = "";
    }
  }
};
</script>

<style lang="less" scoped>
.calculator {
  width: 212px !important;

  input {
    width: 50px;
    height: 50px;
    border: 1px solid black;
    line-height: 50px;
    text-align: center;
    border-radius: 10px;
    outline: none;
  }
}
.answerCard {
  .bottomAnswerBox {
    padding: 0px 16px;
    .jisuanBox {
      margin-top: 20px;
      width: 100%;
      height: 64px;
      background: #ffffff;
      box-shadow: 0px 12px 38px 0px #edf3f6;
      border-radius: 14px;
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      img {
        width: 44px;
        height: 44px;
      }
    }
    .btnBox {
      margin-top: 20px;
      margin-bottom: 20px;
      width: 100%;
      height: 150px;
      background: #ffffff;
      box-shadow: 0px 12px 38px 0px #edf3f6;
      border-radius: 14px;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      div {
        text-align: center;
        line-height: 40px;
        cursor: pointer;
        height: 40px;
        width: 255px;
        border-radius: 6px;
        border: 1px solid #0092ff;
        font-size: 16px;
        font-family: PingFangSC, PingFang SC;
        font-weight: 400;
        color: #0092ff;
      }
    }
  }
  .warp {
    padding: 10px 16px 16px 16px;
    .cardTitle {
      padding-bottom: 16px;
      width: 100%;
      box-sizing: border-box;
      display: flex;
      align-items: center;
      justify-content: flex-start;
      border-bottom: 1px solid #e0e6ed;
      font-size: 16px;
      font-family: PingFangSC, PingFang SC;
      font-weight: 600;
      color: #28313d;
    }
    .card_Box_question_top {
      width: 18px;
      height: 18px;
      color: #ffffff;
      text-align: center;
      line-height: 18px;
      font-size: 13px;
    }
    .card_Box_question {
      cursor: pointer;
      width: 24px;
      height: 24px;
      color: #ffffff;
      text-align: center;
      line-height: 24px;
      font-size: 13px;
      margin-right: 10px;
      margin-top: 12px;
    }
    .colorRight {
      background: #48ba83;
      border-radius: 4px;
      border: 1px solid #ffffff;
    }
    .colorError {
      background: #ff7975;
      border-radius: 4px;
      border: 1px solid #ffffff;
    }
    .colorWei {
      background: #f3f5f6;
      border-radius: 4px;
      border: 1px solid #e0e6ed;
      color: rgb(151, 156, 164);
      position: relative;
      img {
        position: absolute;
        top: 0px;
        left: 0px;
      }
    }
    .cardTop {
      width: 100%;
      display: flex;
      flex-direction: row;
      margin-top: 20px;
      .childBox {
        display: flex;
        flex-direction: row;
        .card_text {
          margin-left: 5px;
          font-size: 16px;
          font-family: PingFangSC, PingFang SC;
          font-weight: 400;
          color: #676f7a;
        }
      }
    }
    .title {
      width: 100%;
      padding: 20px 0 10px 0px;
      box-sizing: border-box;
      display: flex;
      align-items: center;
      justify-content: flex-start;
    }

    .content_new {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      flex-wrap: wrap;
      width: 100%;
      box-sizing: border-box;

      // div {
      //   width: 30px;
      //   height: 30px;
      //   box-sizing: border-box;
      //   border: 1px solid #dddddd;
      //   border-radius: 50%;
      //   margin-right: 5px;
      //   font-size: 16px;
      //   font-family: SourceHanSansCN-Regular, SourceHanSansCN;
      //   font-weight: 400;
      //   color: #333333;
      //   display: flex;
      //   align-items: center;
      //   justify-content: center;
      //   margin-bottom: 10px;
      //   position: relative;
      //   overflow: hidden;
      //   background-color: #ffffff;
      //   cursor: pointer;
      // }

      .doneQuestion {
        background-color: #dce3ff !important;
        color: #5f7cfd;
      }

      .curQuestion {
        background-color: #5f7cfd !important;
        color: #ffffff !important;
      }
    }
  }

  .sign {
    border: 1px solid #dddddd;
    border-radius: 50%;
    position: relative;
    overflow: hidden;
    width: 18px;
    height: 18px;

    span {
      width: 5px;
      height: 4px;
      font-size: 8px;
      line-height: 32px;
      background: orange;
      position: absolute;
      right: 0;
      bottom: 0;
      z-index: 2;
      padding: 0 2em;
      -webkit-transform-origin: left top;
      -moz-transform-origin: left top;
      transform-origin: left top;
      -webkit-transform: translate(29.29%, 100%) rotate(-45deg);
      -moz-transform: translate(29.29%, 100%) rotate(-45deg);
      transform: translate(29.29%, 100%) rotate(-45deg);
      text-indent: 0;
    }
  }
}
</style>